import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 64 64"
    >
      <path fill="#ffeb99" d="M52 52H12V3h32l8 8z"></path>
      <path
        fill="#e9e9ea"
        d="M61 55v4a2.006 2.006 0 01-2 2H39a2.006 2.006 0 01-2-2v-4z"
      ></path>
      <path
        fill="#2488ff"
        d="M39 26h20a2 2 0 012 2v27H37V28a2 2 0 012-2z"
      ></path>
      <path
        fill="#e9e9ea"
        d="M27 55v4a2.006 2.006 0 01-2 2H5a2.006 2.006 0 01-2-2v-4z"
      ></path>
      <path fill="#2488ff" d="M5 26h20a2 2 0 012 2v27H3V28a2 2 0 012-2z"></path>
      <path fill="#e9e9ea" d="M8 32h6v15H8z"></path>
      <path
        fill="#f0d0b4"
        d="M27 48.03a1.994 1.994 0 01-1.4-.71L23.59 45l-1.3-1.51a2.116 2.116 0 113.2-2.77l3.34 3.88a2.017 2.017 0 01.14 2.38 2.513 2.513 0 01-.26.31l-.16.16a1.97 1.97 0 01-1.55.58z"
      ></path>
      <path fill="#9bfbff" d="M54 34h7v10h-7z"></path>
      <path fill="#e9e9ea" d="M48 32h6v13h-6z"></path>
      <path
        fill="#f5e0cd"
        d="M38.16 44a3.845 3.845 0 01-1.1 1.57l-.06.05-2.18 1.92a4.016 4.016 0 01-5.47-.19L29 47l-.03-.02a2.017 2.017 0 00-.14-2.38l-3.34-3.88a2.116 2.116 0 10-3.2 2.77l1.3 1.51H14V34h4l3-3h8.66l-3.02 2.59a2.007 2.007 0 00-.11 2.94l.24.24a2 2 0 002.62.19L32 35l2 2h1l2.5 3a3.987 3.987 0 01.66 4z"
      ></path>
      <path
        fill="#f0d0b4"
        d="M48 34v10h-9.84a3.987 3.987 0 00-.66-4L35 37h-1l-2-2-2.61 1.96a2 2 0 01-2.62-.19l-.24-.24a2.007 2.007 0 01.11-2.94L29.66 31 32 29h7l3 5z"
      ></path>
      <path fill="#ffc477" d="M52 11h-8V3z"></path>
      <path fill="#ff5023" d="M3 34h5v11H3z"></path>
      <rect width="2" height="2" x="14" y="57" rx="1"></rect>
      <path d="M59 25h-6V11h-.01a1 1 0 00-.28-.71l-8-8A1.033 1.033 0 0044 2H12a1 1 0 00-1 1v22H5a3.009 3.009 0 00-3 3v31a3.009 3.009 0 003 3h20a3.009 3.009 0 003-3v-6h8v6a3.009 3.009 0 003 3h20a3.009 3.009 0 003-3V28a3.009 3.009 0 00-3-3zM45 5.41L49.59 10H45zM13 4h30v7a1 1 0 001 1h7v13H39a3.009 3.009 0 00-3 3h-4a.969.969 0 00-.65.24L29.3 30H28v-2a3.009 3.009 0 00-3-3H13zm25.11 30.55L37.38 36h-2.97l-1.7-1.71a1 1 0 00-1.31-.09l-2.61 1.96a1 1 0 01-1.31-.09l-.25-.25a1 1 0 01.06-1.47L32.37 30h6.06l2.71 4.51A.99.99 0 0042 35h5v8h-7.61a5 5 0 00-1.12-3.64L37.14 38H38a.988.988 0 00.89-.55l1-2zM4 28a1 1 0 011-1h20a1 1 0 011 1v2h-5a1.033 1.033 0 00-.71.29L17.59 33H15v-1a1 1 0 00-1-1H8a1 1 0 00-1 1v1H4zm9 5v13H9V33zm-9 2h3v9H4zm22 24a1 1 0 01-1 1H5a1 1 0 01-1-1v-3h22zm0-5H4v-8h3v1a1 1 0 001 1h6a1 1 0 001-1v-1h8.14l1.69 1.96a2.974 2.974 0 001.17.84zm1.84-7.26a.94.94 0 01-.75.3 1.018 1.018 0 01-.74-.38l-3.31-3.83a1.111 1.111 0 01.06-1.51 1.066 1.066 0 01.78-.33h.05a1.083 1.083 0 01.8.39l3.33 3.86a.977.977 0 01-.06 1.34zM36 51h-8v-2.11a2.965 2.965 0 001.01-.53 4.964 4.964 0 006.46-.07l.53-.46zm.4-6.18l-2.24 1.96a3.008 3.008 0 01-4-.05 3.009 3.009 0 00-.57-2.79l-3.35-3.87A3.122 3.122 0 0024 38.99a3.051 3.051 0 00-2.32.91 3.124 3.124 0 00-.25 4.1H15v-9h3a1.033 1.033 0 00.71-.29l2.7-2.71h5.55l-.97.83A3.089 3.089 0 0024.94 35a2.991 2.991 0 00.88 2.23l.25.25a3.028 3.028 0 003.92.28l1.92-1.44 1.38 1.39A1.033 1.033 0 0034 38h.53l2.2 2.64a3.011 3.011 0 01-.33 4.18zM60 59a1 1 0 01-1 1H39a1 1 0 01-1-1v-3h22zm0-5H38v-7.98a5.062 5.062 0 00.77-1.02H47a1 1 0 001 1h6a1 1 0 001-1h5zM49 44V33h4v11zm11-1h-5v-8h5zm0-10h-5v-1a1 1 0 00-1-1h-6a1 1 0 00-1 1v1h-4.43l-2.71-4.51A.99.99 0 0039 28h-1a1 1 0 011-1h20a1 1 0 011 1z"></path>
      <rect width="2" height="2" x="48" y="57" rx="1"></rect>
      <path d="M19 13h2a1 1 0 011 1h2a3 3 0 00-3-3v-1h-2v1a3 3 0 000 6h2a1 1 0 010 2h-2a1 1 0 01-1-1h-2a3 3 0 003 3v1h2v-1a3 3 0 000-6h-2a1 1 0 010-2zM27 10h12v2H27zM27 15h21v2H27zM27 20h21v2H27z"></path>
      <rect width="2" height="2" x="50" y="41" rx="1"></rect>
      <rect width="2" height="2" x="10" y="42" rx="1"></rect>
    </svg>
  );
}

export default Icon;
