export const UPDATE_RESPONSE = "UPDATE_RESPONSE";
export const UPDATE_LOGGED_IN = "UPDATE_LOGGED_IN";
export const UPDATE_CITIES = "UPDATE_CITIES";
export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES";
export const UPDATE_POPULAR_VENUES = "UPDATE_POPULAR_VENUES";
export const UPDATE_FEATURED_VENUE_LOCATION = "UPDATE_FEATURED_VENUE_LOCATION";
export const UPDATE_CART = "UPDATE_CART";
export const UPDATE_ITEMS = "UPDATE_ITEMS";
export const UPDATE_DELIVERY_ADDRESS = "UPDATE_DELIVERY_ADDRESS";
export const UPDATE_WEDDING_CARDS = "UPDATE_WEDDING_CARDS";
export const UPDATE_EVENTS = "UPDATE_EVENTS";
export const REMOVE_EVENTS = "REMOVE_EVENTS";
export const UPDATE_SELECTED_CARD = "UPDATE_SELECTED_CARD";
export const UPDATE_PEROSONAL_DETAILS = "UPDATE_PEROSONAL_DETAILS";
export const UPDATE_GUEST_LIST = "UPDATE_GUEST_LIST";
export const UPDATE_GUEST_EVENT_LIST = "UPDATE_GUEST_EVENT_LIST";
export const UPDATE_PREVIEW = "UPDATE_PREVIEW";
