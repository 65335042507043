import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <g fill="#29485A">
        <path d="M42.667 51.182H153.6v17.067H42.667V51.182zM42.667 85.316H153.6v17.067H42.667V85.316zM42.667 119.449h76.8v17.067h-76.8v-17.067zM42.667 153.582h76.8v17.067h-76.8v-17.067zM0 494.916h512v17.067H0v-17.067z"></path>
      </g>
      <g fill="#ED1C24">
        <path d="M34.859 383.982l-1.451-17.067c178.321-15.454 331.059-133.931 390.357-302.814L444.245 5.7l16.111 5.632-20.48 58.402C378.377 244.983 219.895 367.948 34.859 383.982zM42.667 418.116H102.4v51.2H42.667v-51.2z"></path>
      </g>
      <path
        fill="#FBB03B"
        d="M136.533 392.516h59.733v76.8h-59.733v-76.8z"
      ></path>
      <path
        fill="#F8CF26"
        d="M230.4 349.849h59.733v119.467H230.4V349.849z"
      ></path>
      <path
        fill="#39B54A"
        d="M324.267 290.116H384v179.2h-59.733v-179.2z"
      ></path>
      <path
        fill="#0071BC"
        d="M418.133 170.649h59.733v298.667h-59.733V170.649z"
      ></path>
      <path
        fill="#ED1C24"
        d="M486.4 85.316a8.526 8.526 0 01-7.68-4.719L448.41 20.01l-60.595 30.319c-4.215 2.108-9.344.393-11.452-3.823-2.108-4.215-.393-9.344 3.823-11.452L448.453.921c4.215-2.108 9.344-.401 11.452 3.814v.009l34.133 68.267c2.082 4.224.341 9.344-3.883 11.426a8.553 8.553 0 01-3.755.879z"
      ></path>
    </svg>
  );
}

export default Icon;
