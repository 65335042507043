import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 64 64"
    >
      <path fill="#efbe9a" d="M2 53h10l11 9h21v-6H27l3-3-12-12H2"></path>
      <path
        fill="#f5b953"
        d="M45 21l-3-8 5 2 5-2-3 8M60 47V36c0-7.18-5.82-13-13-13s-13 5.82-13 13v11a2 2 0 100 4h26a2 2 0 100-4z"
      ></path>
      <path
        fill="#f9d18b"
        d="M47 23c-7.18 0-13 5.82-13 13v8.518C36.317 46.082 39.055 47 42 47c8.284 0 15-7.163 15-16 0-1.308-.163-2.572-.441-3.789C54.183 24.628 50.786 23 47 23z"
      ></path>
      <path
        fill="#f5b953"
        d="M29.314 25.553A14.92 14.92 0 0032 17c0-8.284-6.716-15-15-15S2 8.716 2 17s6.716 15 15 15a14.92 14.92 0 008.553-2.686L32 32l-2.686-6.447z"
      ></path>
      <path
        fill="#db9b7c"
        d="M23 48.757h2v8.485h-2z"
        transform="rotate(-45 23.992 53.008)"
      ></path>
      <path fill="#2b74ca" d="M1 38h2v18H1z"></path>
      <path
        fill="#4d5d7a"
        d="M47 32c1.103 0 2 .897 2 2h2c0-1.859-1.28-3.411-3-3.858V28h-2v2.142c-1.72.447-3 1.999-3 3.858 0 2.206 1.794 4 4 4 1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2h-2c0 1.859 1.28 3.411 3 3.858V46h2v-2.142c1.72-.447 3-1.999 3-3.858 0-2.206-1.794-4-4-4-1.103 0-2-.897-2-2s.897-2 2-2z"
      ></path>
      <path fill="#815643" d="M41 20h12v2H41z"></path>
      <g fill="#fff">
        <path d="M12 16c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2zM26 22c0-2.206-1.794-4-4-4s-4 1.794-4 4 1.794 4 4 4 4-1.794 4-4zm-6 0c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2z"></path>
        <path
          d="M5.686 16h22.627v2H5.686z"
          transform="rotate(-45 16.998 17.005)"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
